import React from 'react';
import Layout from '../components/Layout';
import Title from '../components/Title/Title';
import Card from '../components/Card/Card';
import Button from '../components/Button/Button';
import Banner from '../components/Banner/Banner';
import CtaDescList from '../components/CtaDescList/CtaDescList';
import SavingsIcon from '../images/savingsIcon.inline.svg';
import AssistanceIcon  from '../images/assistanceIcon.inline.svg';
import DynamicContent from '../components/DynamicContent/DynamicContent';
import { Column, TwoColumns } from '../components/TwoColumns/TwoColumns';
import Footnotes from '../components/Footnotes/Footnotes';

import { GtagHead } from '../components/GTAG';

export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>LOQTORZI Solutions™ | LOQTORZI® (toripalimab-tpzi)</title>
      <meta name='description'
        content='Financial support to help eligible patients access LOQTORZI® treatment'/>
    </>
  )
}

export default function Solutions() {
  const ctaDescriptionListData = [
    {
      cta: {
        text: <>LOQTORZI<sup>®</sup> at a glance</>,
        url:'/loqtorzi-at-a-glance/'
      },
      text: <div>This module offers you quick <br className='desktop:hidden' />access to key information <br className='desktop:hidden' />about LOQTORZI<sup>®</sup> <br className='hidden large:block' />
        to help you <br className='desktop:hidden' />support your patients.</div>
    }, {
      cta: {
        text: <span>Interactive <br/>
          Prescribing Information </span>,
        url:'/loqtorzi-prescribing-information/'
      },
      text: <div>Explore LOQTORZI<sup>®</sup> data in the
        <br className='desktop:hidden' /> interactive Prescribing Information.</div>
    }, {
      cta: {
        text: 'Get Updates',
        url: '/get-updates/'
      },
      text: <div>Register to receive the latest <br className='desktop:hidden' />information and announcements <br className='desktop:hidden' />about LOQTORZI<sup>®</sup>.</div>
    }
  ];

  return (
    <Layout>
      <Title pageName={'Access & Resources'}>
        Loqtorzi <br className='tablet:hidden' />
        Solutions™
      </Title>

      <DynamicContent header={ <h2><span>Financial support to help eligible patients access LOQTORZI<sup>®</sup> treatment</span></h2> }>
        <div className='wrapperContent'>
          <div className='title mt-[2rem] !mb-0'>{ <h3>How we can help</h3> }</div>
          <TwoColumns className='gap-[1.6rem] pb-0'>
            <Column className='first:!border-0'>
              <Card className='tablet:min-h-[24.5rem] desktop:min-h-[21rem]' content={{ icon: SavingsIcon,title: <h3>Co-Pay<br className='tablet:hidden'/> Savings Program</h3>}}>
                <div className='font-MontserratRegular text-[1.6rem] leading-[2.1rem] text-left'>Helps patients with commercial insurance cover up to $30,000 per calendar year of out-of-pocket costs associated with LOQTORZI<sup>®</sup>. Eligible patients could pay as little as $0 per dose of LOQTORZI<sup>®</sup>.</div>
              </Card>
            </Column>
            <Column>
              <Card className='mt-[2rem] tablet:mt-0 tablet:min-h-[24.5rem] desktop:min-h-[21rem]' content={{ icon: AssistanceIcon,title: <h3>Patient Assistance Program</h3>}}>
                <div className='font-MontserratRegular text-[1.6rem] leading-[2.1rem] text-left'>Helps eligible patients who have no health insurance or have health insurance but are functionally underinsured.*</div>
              </Card>
            </Column>
          </TwoColumns>

          <div className='mt-[2rem] font-MontserratRegular text-[1.6rem] text-center'>Get more information on our<br className='tablet:hidden' /> financial assistance programs and<br className='tablet:hidden' /> eligibility requirements.</div>
          <Button className='m-auto mt-[2.6rem]' url='http://loqtorzisolutions.com' title='LOQTORZI Solutions Enrollment Form' goExternal={ true }>
            <span>See our solutions</span>
          </Button>

          <Footnotes className={'mt-[3rem]'} items={[
            {
              bullet: '*',
              text: <span>Functionally underinsured means the patient does not have insurance or has insurance but has no coverage for LOQTORZI<sup>®</sup>.</span>
            },
            {
              bullet: '',
              text: <span>R/M NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma.</span>
            }
          ]} />

        </div>
        <div className='wrapperContent !px-0'>
          <Banner>
            <span>For more financial or practice resources from LOQTORZI Solutions™, call 1-800-483-3692 or visit LOQTORZISolutions.com</span>
          </Banner>
        </div>
      </DynamicContent>

      <CtaDescList content={ctaDescriptionListData} />
    </Layout>
  );
}